import React from "react"
import { Image, Text } from "../../components/Core"

function BannerSection(props) {
  return (
    <div
      className={`columns is-vcentered banner-section invert-link-color ${
        props.hasTextCentered ? "has-text-centered" : ""
      }`}>
      <div className="column is-3"></div>
      <div className="column is-4">
        <Image publicId={props.post.bannerSection.logoId} />
      </div>
      <div className="column is-1"></div>
      <div className="column">
        <Text text={props.post.bannerSection.text} />
      </div>
      <div className="column is-2"></div>
    </div>
  )
}

export default BannerSection
